// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-default-page-js": () => import("../src/templates/DefaultPage.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-about-page-js": () => import("../src/templates/AboutPage.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-contact-page-js": () => import("../src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-team-member-page-js": () => import("../src/templates/TeamMemberPage.js" /* webpackChunkName: "component---src-templates-team-member-page-js" */),
  "component---src-templates-virtual-reality-page-js": () => import("../src/templates/VirtualRealityPage.js" /* webpackChunkName: "component---src-templates-virtual-reality-page-js" */),
  "component---src-templates-home-page-js": () => import("../src/templates/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-investor-page-js": () => import("../src/templates/InvestorPage.js" /* webpackChunkName: "component---src-templates-investor-page-js" */),
  "component---src-templates-subscribe-page-js": () => import("../src/templates/SubscribePage.js" /* webpackChunkName: "component---src-templates-subscribe-page-js" */),
  "component---src-templates-technology-page-js": () => import("../src/templates/TechnologyPage.js" /* webpackChunkName: "component---src-templates-technology-page-js" */),
  "component---src-templates-blog-index-js": () => import("../src/templates/BlogIndex.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-research-index-js": () => import("../src/templates/ResearchIndex.js" /* webpackChunkName: "component---src-templates-research-index-js" */),
  "component---src-templates-single-post-js": () => import("../src/templates/SinglePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-single-research-post-js": () => import("../src/templates/SingleResearchPost.js" /* webpackChunkName: "component---src-templates-single-research-post-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

